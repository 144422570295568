import React from "react";
import Layout from "../Layout";
import Branch from "../create/Branch";
import Get from "../../../../services/Get";
import DimsDataTable from "../../../../components/DimsDataTable";
import helpers from "../../../../helpers/";
import DimsButton from "../../../../components/DimsButton";
class Branches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      off: false,
      brachData: [],
    };
    this.toggleOffCanvas = this.toggleOffCanvas.bind(this);
    this.editClick = this.editClick.bind(this);
    this.load();
  }
  load() {
    var url = "/general/Branch";
    Get(url, "")
      .then((result) => {
        console.log(result);
        this.setState({ brachData: result.data });
      })
      .catch((error) => {
        helpers.error(error);
      });
  }

  onChange(e) {
    alert(e.target.value);
  }
  toggleOffCanvas() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
    this.load();
  }
  editClick(row) { 
    this.setState((prevState) => ({
      off: !prevState.off,
      uuid: row.guid,
    }));
  }
  render() {
    var columns = [
      {
        name: "Name",
        selector: (row) => row.branchName,
      },
      {
        name: "Description",
        selector: (row) => row.branchAddress,
      },
      {
        name: "",
        selector: (row) => (
          <div>
            <DimsButton
              type="edit"
              onClick={(e) => this.editClick(row)}
            ></DimsButton>{" "}
          </div>
        ),
      },
    ];
    return (
      <Layout title="Branches" isButton={true} url={this.toggleOffCanvas}>
        <Branch
          isOpen={this.state.off}
          uuid={this.state.uuid}
          toggle={this.toggleOffCanvas}
        ></Branch>
        <DimsDataTable data={this.state.brachData} columns={columns} />
      </Layout>
    );
  }
}

export default Branches;
