import React, { Component } from "react";
import {
  UncontrolledDropdown,
  NavLink,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
class Menu extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.menu);
  }
  render() {
    return (
      <div>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {this.props.menu.name}
          </DropdownToggle>
          <DropdownMenu end>
            {this.props.menu.childMenus.map((menu, i) => {
              return (
                <DropdownItem>
                  <NavLink className="p-0 m-0" href={menu.url}>
                    {menu.name}
                  </NavLink>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}

export default Menu;
