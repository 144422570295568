import React, { Fragment } from "react";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

export default class TaskNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <Fragment>
        <Navbar
          container
          className="navbar navbar-expand-lg   navbar-dark "
          expand="md"
        >
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav vertical className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/task/all">Task</NavLink>
                <NavLink href="/task/employees">Assigned Task</NavLink>
                <NavLink href="/task/reports">Reports</NavLink>
              </NavItem>
        
            </Nav>
          </Collapse>
        </Navbar>
      </Fragment>
    );
  }
}
