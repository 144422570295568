import React from "react";
import { Row, Col, Container } from "reactstrap";
import HRNavigation from "./HRNavigation";
import  DimsCard from "../../components/DimsCard"

class Layout extends React.Component {
  render() {
    return (
      <Container fluid>
        <Row className="px-0">
          <Col className="px-0" md="2">
            <div id="sidebar">
              <HRNavigation />
            </div>
          </Col>
          <Col className="px-0" md="10" >
            <DimsCard
              title={this.props.title}
              isButton={this.props.isButton}
              linkType= { this.props.linkType}
              url={this.props.url}
            >
              {this.props.children}
            </DimsCard>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Layout;
