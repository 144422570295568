import React from "react";
import Layout from "../Layout";
import Task from "../create/Task";
import Get from "../../../services/Get";
import DimsDataTable from "../../../components/DimsDataTable";
import helpers from "../../../helpers/";
import DimsButton from "../../../components/DimsButton";
import DimsDateFormat from "../../../components/DimsDateFormat";
import TaskView from "../views/TaskView";

const ExpandedComponent = ({ data }) => <TaskView data={data}></TaskView>;

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      off: false,
      taskData: [],
      uuid: "",
      task: {},
      projectSelect: [],
      employeeId: JSON.parse(sessionStorage.getItem("user")).employeeId,
    };
    this.toggleOff = this.toggleOff.bind(this);
    this.load();
  }

  load() {
    var url = "/task/ProjectTask";
    Get(url, "")
      .then((result) => {
        console.log(result);
        this.setState({ taskData: result.data });
      })
      .catch((error) => {
        helpers.error(error);
      });
  }
  editClick(row) {
    var url = "/task/ProjectTask/single/" + row.uuid;
    Get(url, "").then((result) => {
      result = result.data;
      console.log(result);
      this.setState({
        task: result,
        employeeSelect: [
          { value: result.employeeId, label: result.employee.employeeName },
        ],
        projectSelect: [
          {
            value: result.project.projectId,
            label: result.project.projectName,
          },
        ],
        off: !this.state.off,
        uuid: row.uuid,
      });
    });
  }
  toggleOff() {
    this.setState({
      task: null,
      employeeSelect: [],
      projectSelect: [],
      off: !this.state.off,
    });
    this.load();
  }
  render() {
    var columns = [
      {
        name: "Assigned Date",
        selector: (row) => (
          <div>{helpers.timestampToDate(row.uiTimeStamp)}</div>
        ),
      },
      {
        name: "Completion Date",
        selector: (row) => (
          <div>
            {" "}
            <DimsDateFormat date={row.completionDate}></DimsDateFormat>{" "}
          </div>
        ),
      },
      {
        name: "Name",
        selector: (row) => row.taskName,
      },
      {
        name: "Created By",
        selector: (row) => row.createdEmployee.employeeName,
      },

      {
        name: " Assigned to",
        selector: (row) => row.employee.employeeName,
      },
      // {
      //   name: "Project",
      //   selector: (row) => row.project.projectName,
      // },
      {
        name: "Status",
        selector: (row) => row.taskStatus.taskStatusName,
      },

      {
        name: "",
        selector: (row) => (
          <div>
            {this.state.employeeId === row.createdEmployee.employeeId && (
              <DimsButton
                type="edit"
                onClick={(e) => this.editClick(row)}
              ></DimsButton>
            )}
          </div>
        ),
      },
    ];
    return (
      <Layout title="Tasks" isButton={true} url={this.toggleOff}>
        <Task
          uuid={this.state.uuid}
          isOpen={this.state.off}
          toggle={this.toggleOff}
          task={this.state.task}
          employeeSelect={this.state.employeeSelect}
          projectSelect={this.state.projectSelect}
        ></Task>
        <DimsDataTable
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          data={this.state.taskData}
          columns={columns}
        />
      </Layout>
    );
  }
}

export default Tasks;
