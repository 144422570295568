import React, { useEffect }  from "react";
import { createRoot } from "react-dom/client";
import Main from "./app/main";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootswatch/dist/cerulean/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import "./index.css";

function AppWithCallbackAfterRender() {
  useEffect(() => {
    console.log("Welcome to Ozone ERP");
  });

  return (
    <BrowserRouter> 
      <Main /> 
      <ToastContainer />
    </BrowserRouter>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />); 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
