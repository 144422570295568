import React from "react";
import Layout from "../Layout";
import Project from "../create/Project";
import Get from "../../../../services/Get";
import DimsDataTable from "../../../../components/DimsDataTable";
import helpers from  "../../../../helpers/"; 

class Projectes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      off: false,
      projectData: [],
    }; 
    this.toggleOffCanvas = this.toggleOffCanvas.bind(this);
    this.load();
  }
  load() {
    var url = "/task/Project/all";
    Get(url, "").then((result) => {
      console.log(result);
      this.setState({ projectData: result.data });
    }).catch(error => {
      helpers.error(error);
    });
  }
  
  
  toggleOffCanvas() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
    this.load();
  }

  render() {
    var columns = [
      {
        name: "Name",
        selector: (row) => row.projectName,
      },
      {
        name: "Description",
        selector: (row) => row.projectDetails,
      },
      {
        name: "",
        selector: (row) => <div> </div>,
      },
    ];
    return (
      <Layout title="Projects" isButton={true} url={this.toggleOffCanvas}>
        <Project isOpen={this.state.off} toggle={this.toggleOffCanvas}></Project>
        <DimsDataTable data={this.state.projectData} columns={columns} />
      </Layout>
    );
  }
}

export default Projectes;
