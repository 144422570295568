import React from "react";
import { Button, Tooltip } from "reactstrap";
import { VscSave, VscEdit, VscTrash } from "react-icons/vsc";
import { FiPlus } from "react-icons/fi";
import { HiArrowSmRight } from "react-icons/hi";

class DimsButton extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.type === "create" && (
          <Button
            type="button"
            color="info"
            {...this.props}
            size="md"
            id="TooltipExample"
          >
            <FiPlus />
            <Tooltip
              flip
              target="TooltipExample"
              toggle={function noRefCheck() {}}
            ></Tooltip>
          </Button>
        )}
        {this.props.type === "edit" && (
          <Button
            type="button"
            color="primary"
            className="m-1"
            {...this.props}
            size="sm"
          >
            <VscEdit />
          </Button>
        )}

        {this.props.type === "login" && (
          <Button
            type="submit"
            color="info"
            className="m-1"
            {...this.props}
            size="md"
          >
            Login <HiArrowSmRight />
          </Button>
        )}
        {this.props.type === "delete" && (
          <Button type="button" color="danger" {...this.props} size="md">
            <VscTrash />
          </Button>
        )}
        {this.props.type === "submit" && (
          <Button type={this.props.type} color="info" {...this.props} size="md">
            <VscSave />
          </Button>
        )}
         {this.props.type === "search" && (
          <Button type="button" color="info" {...this.props} size="md">
            <VscSave /> Search
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default DimsButton;
