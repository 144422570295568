import React from "react";
import DataTable from "react-data-table-component";

class DimsDataTable extends React.Component {
  render() {

    return (
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="600px"
        {...this.props}
        pagination
      />
    );
  }
}

export default DimsDataTable;
