import React from "react";
import Layout from "../Layout";
import DimsButton from "../../../components/DimsButton";
import DimsFromGroup from "../../../components/DimsFromGroup";
import helpers from "../../../helpers";
import FocusLock, { AutoFocusInside } from "react-focus-lock";
import { Col, Form, Row } from "reactstrap";
import Get from "../../../services/Get";
import Post from "../../../services/Post";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import DimsSelect from "../../../components/DimsSelect";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TaskItem from "./TaskItem";
import { useParams } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import moment from "moment";

const TaskStatus = () => {
  const { uuid } = useParams();
  return <TaskStatusMain uuid={uuid}></TaskStatusMain>;
};
class TaskStatusMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      off: false,
      statusOptions: [],
      startDate: "",
      time: "",
      assignedTasks: [],
      assignedTask: {},
      task: "",
      editorState: EditorState.createEmpty(),
      employeeId: JSON.parse(sessionStorage.getItem("user")).employeeId,
    };
    console.log(this.state.employeeId);

    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
    this.onChange = (editorState) => this.setState({ editorState });
    this.handleSubmit = this.handleSubmit.bind(this);
    this.load();
  }
  load() {
    var url = "/task/ProjectTaskStatus";
    Get(url, "").then((result) => {
      var status = [];
      result.data.map((row, i) => {
        return status.push({
          value: row.taskStatusId,
          label: row.taskStatusName,
        });
      });
      this.setState({ statusOptions: status });
    });

    url = "/task/ProjectTaskAssigned/assigned/" + this.props.uuid;
    Get(url, "").then((result) => {
      this.setState({
        task: result.data.task,
        assignedTasks: result.data.assignedTasks,
        assignedTask: result.data.task,
      });
      console.log(this.state.assignedTask);
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    var time = moment(new Date()).format("X");
    var edit = e.target.editorvalue.value.toString(); // this.state.editorState.getCurrentContent();
    let result = edit.replace("<p></p>", "");
    result = result.trim();
    if (result.length === 0) {
      helpers.error("Enter comment");
      return;
    }
    var status = e.target.Status.value;
    if (status.trim().length === 0) {
      helpers.error("Enter Status");
      return;
    }

    var data = {
      taskId: this.state.task.id,
      taskStatusId: status,
      taskAssignedDescription: result,
      employeeId: this.state.employeeId,
      uiTimeStamp: time,
    };
    var url = "/task/ProjectTaskAssigned";
    Post(url, data)
      .then((result) => {
        if (result.status === 200) {
          helpers.success("Saved Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        console.log(result);
      })
      .catch(({ response }) => {
        console.log(response);
        //helpers.error(response.data.message);
        // console.log(response.status);
        // console.log(response.headers);
      });
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (e.target.name === "name") {
      }
    }
  }
  toggleOff() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
  }
  render() {
    return (
      <Layout
        title="Update Tasks"
        isButton={false}
        linkType="back"
        url="/task/employees"
      >
        <Row>
          <Col xs="12" md="6" size="md">
            {" "}
            <FocusLock>
              <table>
                <tr>
                  <td>Task Name</td>
                  <td>{this.state.assignedTask.taskName}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{this.state.assignedTask.taskDescription}</td>
                </tr>
                <tr>
                  <td>Completion Date  :</td>
                  <td>
                    { helpers.dateFormat(this.state.assignedTask.completionDate) }
                  </td>
                </tr>
              </table>
              <Form id="form" onSubmit={this.handleSubmit}>
                <DimsFromGroup label="">
                  <AutoFocusInside>
                    <Editor
                      editorState={this.state.editorState}
                      wrapperClassName="dims-wrapper"
                      editorClassName="dims-editor"
                      onEditorStateChange={this.onChange}
                      style={{ border: "2px solid #ddd", borderRadius: "5px" }}
                    />
                  </AutoFocusInside>
                  <textarea
                    hidden
                    name="editorvalue"
                    disabled
                    value={draftToHtml(
                      convertToRaw(this.state.editorState.getCurrentContent())
                    )}
                  />
                </DimsFromGroup>
                <DimsFromGroup label="Select Status">
                  <DimsSelect
                    tabIndex="2"
                    name="Status"
                    options={this.state.statusOptions}
                  />
                </DimsFromGroup>

                <DimsButton tabIndex="6" type="submit"></DimsButton>
              </Form>
            </FocusLock>
          </Col>
          <Col xs="12" md="6">
            <div
              className="w-100"
              style={{ border: "2px solid #ddd", borderRadius: "5px" }}
            >
              {this.state.assignedTasks.map((row, i) => {
                return <TaskItem row={row} />;
              })}
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}
export default TaskStatus;
