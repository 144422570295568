import React from "react";
import DatePicker from "react-datepicker";

class DimsDatePicker extends React.Component {
  render() {
    return (
      <DatePicker
        dateFormat="dd-MM-yyyy"
        className="form-control form-control-sm"
        {...this.props}
      />
    );
  }
}

export default DimsDatePicker;
