import { Component } from "react";

class Logout extends Component {
  render() {
    sessionStorage.setItem("isLogin", 0);
    sessionStorage.setItem("user", "");
    window.location.reload();
    return 1;
  }
}

export default Logout;
