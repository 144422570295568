import React from "react";
import DimsOffcanvas from "../../../../components/DimsOffcanvas";
import DimsInput from "../../../../components/DimsInput";
import DimsButton from "../../../../components/DimsButton";
import DimsFromGroup from "../../../../components/DimsFromGroup";
import helpers from "../../../../helpers";
import Company from "./Company";
import FocusLock, { AutoFocusInside } from "react-focus-lock";
import { Form } from "reactstrap";
import Get from "../../../../services/Get";
import DimsSelect from "../../../../components/DimsSelect";
import Post from "../../../../services/Post";

class Branch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      off: false,
      companyOptions: [],
    };
    this.nameInput = React.createRef();
    this.toggleOffCanvas = this.toggleOffCanvas.bind(this);
    this.load();
    this.setTextInputRef = (element) => {
      this.nameInput = element;
    };
    this.onOpened = this.onOpened.bind(this);
  }
  load() {
    var url = "/general/Company";
    Get(url, "").then((result) => {
      console.log(result);
      var company = [];
      result.data.map((row, i) => {
        return company.push({ value: row.companyId, label: row.companyName });
      });
      this.setState({ companyOptions: company });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (e.target.Company.value === "") {
      helpers.error("Select Company");
      document.getElementById("Company").focus();
      return;
    }
    var data = {
      companyId: e.target.Company.value,
      branchName: e.target.Name.value,
      branchAddress: e.target.Address.value,
      branchPhone: e.target.Phone.value,
      branchDescription: e.target.Description.value,
    };
    var url = "/general/Branch";
    Post(url, data)
      .then((result) => {
        if (result.status === 200) {
          helpers.success("Saved Successfully");
          document.getElementById("form").reset();
          document.getElementById("Name").focus();
        }
        console.log(result);
      })
      .catch(({ response }) => {
        // console.log(response.data);
        helpers.error(response.data.message);
        // console.log(response.status);
        // console.log(response.headers);
      });
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (e.target.name === "name") {
      }
    }
  }
  toggleOffCanvas() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
  }
  onOpened() {
    // alert('test')
    //  alert(this.props.uuid)
  }
  render() {
    return (
      <DimsOffcanvas
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title="Create Branch"
        onOpened={this.onOpened}
      >
        <FocusLock>
          <Company
            isOpen={this.state.off}
            toggle={this.toggleOffCanvas}
          ></Company>
          <Form id="form" onSubmit={this.handleSubmit}>
            <DimsFromGroup label="Name">
              <AutoFocusInside>
                <DimsInput
                  tabIndex="1"
                  name="Name"
                  id="Name"
                  onKeyDown={this.onKeyDown}
                  required
                />
              </AutoFocusInside>
            </DimsFromGroup>
            <DimsFromGroup label="Phone">
              <DimsInput tabIndex="2" name="Phone" id="Phone" required />
            </DimsFromGroup>
            <DimsFromGroup label="Select Company">
              <DimsSelect
                tabIndex="3"
                name="Company"
                id="Company"
                required
                options={this.state.companyOptions}
              />
            </DimsFromGroup>
            <DimsFromGroup label="Address">
              <DimsInput
                name="Address"
                id="Address"
                tabIndex="4"
                type="textarea"
              />
            </DimsFromGroup>
            <DimsFromGroup label="Description">
              <DimsInput
                name="Description"
                id="Description"
                tabIndex="5"
                type="textarea"
              />
            </DimsFromGroup>
            <DimsButton tabIndex="6" type="create"></DimsButton>
          </Form>
        </FocusLock>
      </DimsOffcanvas>
    );
  }
}

export default Branch;
