import React, { Fragment } from "react";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

export default class GeneralNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <Fragment>
        <Navbar
          container
          className="navbar navbar-expand-lg   navbar-dark "
          expand="md"
        >
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav vertical className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/general/companies">Companies</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/general/braches">Braches</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/general/projects">Projects</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/general/users">Users</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/general/designation">Designation</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/general/taskstatus">Task Status</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Fragment>
    );
  }
}
