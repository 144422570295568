import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Tasks from "./list/Task";
import AssignedTasks from "./list/AssignedTasks";
import TaskStatus from "./create/TaskStatus";
import TaskReport from "./reports/TaskReport";
export default class TaskRoutes extends React.Component {
   
  render() {
    return (
      <Routes>
        <Route path="all" element={<Tasks />}></Route>
        <Route path="employees" element={<AssignedTasks />}></Route>
        <Route path="reports" element={<TaskReport />}></Route>
        <Route path="update/:uuid" element={<TaskStatus />}></Route>
        <Route path="*" element={<Navigate to="/404" />}></Route>
      </Routes>
    );
  }
}
