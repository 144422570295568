import React from "react";
import * as moment from "moment";
 

export default class DimsDateTimeFormat extends React.Component {
  render() {
    return ( 
        <React.Fragment>
        {moment(this.props.date).format("DD-MM-YYYY hh:mm A")}
      </React.Fragment>
    );
  }
}
