import React from "react";
import { Col, Row } from "reactstrap";
import Get from "../services/Get";
import DimsCard from "../components/DimsCard";
import DimsDataTable from "../components/DimsDataTable";
import DimsLink from "../components/DimsLink";
import DimsDateFormat from "../components/DimsDateFormat";
import DimsDatePicker from "../components/DimsDatePicker";
import DimsButton from "../components/DimsButton";
import helpers from "../helpers";
import Post from "../services/Post";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: [],
      pendingTask: [],
      employeeId: JSON.parse(sessionStorage.getItem("user")).employeeId,
      startDate: new Date(),
      endDate: new Date(),
    };
    this.load = this.load.bind(this);
    this.datechange = this.datechange.bind(this);
    this.enddatechange = this.enddatechange.bind(this);
    this.pendingSearch = this.pendingSearch.bind(this);
    this.loadPendingTasks = this.loadPendingTasks.bind(this);
    this.load();
  }
  componentDidMount() {
    setInterval(this.load, 8000);
  }
  datechange(e) {
    this.setState({ startDate: e });
  }
  enddatechange(e) {
    this.setState({ endDate: e });
  }
  pendingSearch() {
    
    this.loadPendingTasks(
      helpers.systemdateFormat(this.state.startDate),
      helpers.systemdateFormat(this.state.endDate)
    );
  }

  render() {
    var columns = [
      {
        name: "Name",
        wrap: true,
        selector: (row) => row.projectTask.taskName,
      },
      {
        name: "Completion Date",
        selector: (row) => (
          <div>
            <DimsDateFormat
              date={row.projectTask.completionDate}
            ></DimsDateFormat>
          </div>
        ),
      },

      {
        name: "",
        selector: (row) => (
          <div>
            <DimsLink
              type="edit"
              to={"/task/update/" + row.projectTask.uuid}
            ></DimsLink>
          </div>
        ),
      },
    ];

    var pendingcolumns = [
      {
        name: "Name",
        wrap: true,
        selector: (row) => row.taskName,
      },
      {
        name: "Completion Date",
        selector: (row) => (
          <div>
            <DimsDateFormat
              date={row.completionDate}
            ></DimsDateFormat>
          </div>
        ),
      },

      {
        name: "",
        selector: (row) => (
          <div>
            <DimsLink
              type="edit"
              to={"/task/update/" + row.uuid}
            ></DimsLink>
          </div>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col sm="12" md="6">
            <DimsCard title="Notifications">
              <DimsDataTable data={this.state.notification} columns={columns} />
            </DimsCard>
          </Col>
          <Col sm="12" md="6">
            <DimsCard title="Pending Task">
              <Row>
                <Col sm="12" md="4">
                  <DimsDatePicker
                    selected={this.state.startDate}
                    onChange={this.datechange}
                  />
                </Col>
                <Col sm="12" md="4">
                  <DimsDatePicker
                    selected={this.state.endDate}
                    onChange={this.enddatechange}
                  />
                </Col>
                <Col sm="12" md="4">
                  <DimsButton type="search" onClick={this.pendingSearch} />
                </Col>
              </Row>
              <DimsDataTable data={this.state.pendingTask} columns={pendingcolumns} />
            </DimsCard>
          </Col>
        </Row>
        {/* <Row>
          <Col sm="12" md="6">
            <DimsCard title="Notifications">
              <DimsDataTable data={this.state.notification} columns={columns} />
            </DimsCard>
          </Col>
          <Col sm="12" md="6">
            <DimsCard title="Pending Task">
              <DimsDataTable data={this.state.notification} columns={columns} />
            </DimsCard>
          </Col>
        </Row> */}
      </div>
    );
  }
  loadPendingTasks(start, end) {
    var data = {
      employeeId: 4,
      projectId: 0,
      startDate: "2022-04-22",
      endDate: "2022-04-23",
      statusId: 3,
      isMyTask: 0,
    };
    var url = "/task/ProjectTask/report";
    Post(url, data).then((result) => {
      console.log(result);
      this.setState({ pendingTask: result.data });
    });
  }
  load() {
    var url = "/api/Notification";
    Get(url, "").then((result) => {
      console.log(result);
      this.setState({ notification: result.data });
    });
  }
}

export default Dashboard;
