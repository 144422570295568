import React from "react";
import Layout from "../Layout";
import User from "../create/User";
import Get from "../../../../services/Get";
import DimsDataTable from "../../../../components/DimsDataTable";
import helpers from "../../../../helpers/";
import DimsButton from "../../../../components/DimsButton";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      off: false,
      projectData: [],
      user: null,
      uuid: "",
      designationSelect: [],
      userTypeSelect: [],
      reportingSelect: [],
      companySelect: [],
    };

    this.toggleOff = this.toggleOff.bind(this);
    this.load();
  }
  load() {
    var url = "/general/Employee";
    Get(url, "")
      .then((result) => {
        console.log(result);
        this.setState({ projectData: result.data });
      })
      .catch((error) => {
        helpers.error(error);
      });
  }
  editClick(row) {
    var url = "/general/Employee/single/" + row.uuid;
    Get(url, "").then((result) => {
      result = result.data;
      console.log(result);
      this.setState({
        user: result,
        designationSelect: [
          {
            value: result.employee.designation.id,
            label: result.employee.designation.name,
          },
        ],
        companySelect: [
          {
            value: result.user.company.id,
            label: result.user.company.companyName,
          },
        ],
        off: !this.state.off,
        uuid: row.uuid,
      });

      if (result.employee.reportingPerson != null)
      {
        this.setState({
          
          reportingSelect: [
            {
              value: result.employee.reportingPerson.id,
              label: result.employee.reportingPerson.employeeName,
            },
          ] 
        });
      }
    });
  }

  toggleOff() {
    this.setState((prevState) => ({
      off: !prevState.off,
      user: null,
    }));
    this.load();
  }

  render() {
    var columns = [
      {
        name: "Name",
        selector: (row) => row.employeeName,
      },
      {
        name: "Reporting Person",
        selector: (row) =>
          row.reportingPerson && row.reportingPerson.employeeName,
      },
      {
        name: "Designation",
        selector: (row) => row.designation && row.designation.name,
      },
      {
        name: "Email",
        selector: (row) => row.employeeEmail,
      },
      {
        name: "Phone",
        selector: (row) => row.employeePhone,
      },
      {
        name: "Description",
        selector: (row) => row.employeeDescription,
      },
      {
        name: "",
        selector: (row) => (
          <div>
            <DimsButton
              type="edit"
              onClick={(e) => this.editClick(row)}
            ></DimsButton>
          </div>
        ),
      },
    ];
    return (
      <Layout title="Users" isButton={true} url={this.toggleOff}>
        <User
          uuid={this.state.uuid}
          user={this.state.user}
          isOpen={this.state.off}
          toggle={this.toggleOff}
          designationSelect= { this.state.designationSelect}
          companySelect={this.state.companySelect}
          reportingSelect ={ this.state.reportingSelect}
        ></User>
        <DimsDataTable data={this.state.projectData} columns={columns} />
      </Layout>
    );
  }
}

export default Users;
