import React from "react";
import Select from "react-select";

class DimsSelect extends React.Component {
  render() { 
    return <Select size="sm" {...this.props} />;
  }
}

export default DimsSelect;
