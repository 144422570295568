import React, { Component } from "react";
import { Table } from "reactstrap";

class TaskView extends Component {
  render() {
    return (
      <div>
        <Table>
          <tr>
            <td> Description </td>
            <td> {this.props. data.taskDescription} </td>
          </tr>
          <tr>
            <td> Project </td>
            <td> {this.props.data.project.projectName} </td>
          </tr>
        </Table>
      </div>
    );
  }
}

export default TaskView;
