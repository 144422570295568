import React from "react";
import DimsOffcanvas from "../../../../components/DimsOffcanvas";
import DimsInput from "../../../../components/DimsInput";
import { Form, FormGroup, Label } from "reactstrap";
import DimsButton from "../../../../components/DimsButton";
import helpers from "../../../../helpers";
import FocusLock, { AutoFocusInside } from "react-focus-lock";
import Post from "../../../../services/Post";

export default class Project extends React.Component {
  handleSubmit(e) {
    e.preventDefault();
    var data = {
      projectName: e.target.Name.value,
      projectDetails: e.target.Description.value,
    };
    var url = "/task/Project";
    Post(url, data)
      .then((result) => {
        if (result.status === 200) {
          helpers.success("Saved Successfully");
          document.getElementById("form").reset();
          document.getElementById("Name").focus();
        }
        console.log(result);
      })
      .catch(({ response }) => {
        console.log(response);
        helpers.error(response.data.message);
      });
  }
  onKeyDown(e) {
    if (e.keyCode === 13 && e.target.value !== "") {
      document.getElementById("description").focus();
    }
  }
  render() {
    return (
      <DimsOffcanvas
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title="Create Project"
      >
        <FocusLock>
          <Form id="form" onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label> Name </Label>
              <AutoFocusInside>
                <DimsInput
                  name="Name"
                  id="Name"
                  onKeyDown={this.onKeyDown}
                  required
                />
              </AutoFocusInside>
            </FormGroup>
            <FormGroup>
              <Label> Description </Label>
              <DimsInput name="Description" id="Description" type="textarea" />
            </FormGroup>
            <DimsButton color="primary" type="submit">
              Save
            </DimsButton>
          </Form>
        </FocusLock>
      </DimsOffcanvas>
    );
  }
}
