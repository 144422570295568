import React from "react";
import {  FormGroup, Label } from "reactstrap";

class DimsFromGroup extends React.Component {
  render() {
    return (
      <FormGroup>
        <Label> {this.props.label} </Label>
        {this.props.children}
      </FormGroup>
    );
  }
}

export default DimsFromGroup;
