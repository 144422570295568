import React from "react";
import DimsOffcanvas from "../../../../components/DimsOffcanvas";
import DimsInput from "../../../../components/DimsInput";
import { Form, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import helpers from "../../../../helpers";
import FocusLock, { AutoFocusInside } from "react-focus-lock";
import DimsButton from "../../../../components/DimsButton";
import DimsFromGroup from "../../../../components/DimsFromGroup";
import Post from "../../../../services/Post";
import DimsSelect from "../../../../components/DimsSelect";
import Get from "../../../../services/Get";
import classnames from "classnames";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    var typeopt = [];
    typeopt.push({ value: 0, label: "Employee" });
    typeopt.push({ value: 1, label: "Admin" });
    this.state = {
      userOptions: [],
      designationOptions: [],
      companyOptions: [],
      typeOptions: typeopt,
      activeTab: "1",
      typeSelect: [{ value: 0, label: "Employee" }],
      Id: 0,
      Name: "",
      Description: "",
      Email: "",
      Phone: "",
      Address: "",
      Password: "",
      Monthly: "",
      Hourly: "",
      Employee: "",
      Designation: "",
      Company: "",
      Type: "",
      companySelect: "",
    };
    this.toggle = this.toggle.bind(this);
    this.onOpend = this.onOpend.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.load();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  setMyValue(type, e) {
    this.setState({ [type]: e.target.value });
  }

  load() {
    var url = "/general/Employee";
    Get(url, "").then((result) => {
      console.log(result);
      var user = [];
      result.data.map((row, i) => {
        return user.push({ value: row.employeeId, label: row.employeeName });
      });
      this.setState({ userOptions: user });
    });
    url = "/general/Designation";
    Get(url, "").then((result) => {
      console.log(result);
      var designation = [];
      result.data.map((row, i) => {
        return designation.push({ value: row.id, label: row.name });
      });
      this.setState({ designationOptions: designation });
    });
    url = "/general/Company";
    Get(url, "").then((result) => {
      console.log(result);
      var company = [];
      result.data.map((row, i) => {
        return company.push({ value: row.companyId, label: row.companyName });
      });
      this.setState({ companyOptions: company });
    });
  }
  clearForm() {
    this.setState({
      Id: 0,
      Name: "",
      Description: "",
      Email: "",
      Phone: "",
      Address: "",
      Password: "",
      Monthly: "",
      Hourly: "",
      Employee: "",
      Designation: "",
      Company: "",
      Type: "",
    });
  }
  onOpend() {
    if (this.props.user !== null)
      this.setState({
        Id: this.props.user.employee.id,
        Name: this.props.user.employee.employeeName,
        Address: this.props.user.employee.employeeAddress,
        Description: this.props.user.employee.employeeDescription,
        Email: this.props.user.employee.employeeEmail,
        Phone: this.props.user.employee.employeePhone,
        Monthly: this.props.user.employee.monthlySalary,
        Hourly: this.props.user.employee.hourlySalary,
        Employee: this.props.user.employee.employeeName,
        Designation: this.props.user.employee.employeeName,
        Company: this.props.user.employee.employeeName,
        Type: this.props.user.employee.employeeName,
      });
    else {
      this.clearForm(); 
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    var Designation = e.target.Designation.value;
    if (Designation.trim().length === 0) {
      helpers.error("Select Designation");
      return;
    }
    var Company = e.target.Company.value;
    if (Company.trim().length === 0) {
      helpers.error("Select Company");
      return;
    }
    var url = "/general/Employee";
    var data = {
      employeeId: this.state.Id,
      employeeName: e.target.Name.value,
      employeeDescription: e.target.Description.value,
      employeeEmail: e.target.Email.value,
      employeePhone: e.target.Phone.value,
      employeeAddress: e.target.Address.value,
      employeePassword: e.target.Password.value,
      monthlySalary: e.target.Monthly.value,
      hourlySalary: e.target.Hourly.value,
      reporting: e.target.Employee.value,
      designationId: e.target.Designation.value,
      companyId: Company,
      userType: e.target.Type.value,
    };
    Post(url, data)
      .then((result) => {
        if (result.status === 200) {
          helpers.success("Saved Successfully");
          document.getElementById("form").reset();
          document.getElementById("Name").focus();
          this.clearForm();
        }
        console.log(result);
      })
      .catch(({ response }) => {
        console.log(response.data);
        helpers.error(response.data.message);
        console.log(response.status);
        console.log(response.headers);
      });
  }
  onKeyDown(e) {
    if (e.keyCode === 13 && e.target.value !== "") {
      document.getElementById("description").focus();
    }
  }
  render() {
    return (
      <DimsOffcanvas
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title="Create Employee"
        onOpened={this.onOpend}
      >
        <FocusLock>
          <Form id="form" onSubmit={this.handleSubmit}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Employee
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Details
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <DimsFromGroup label="Name">
                  <AutoFocusInside>
                    <DimsInput
                      name="Name"
                      id="Name"
                      value={this.state.Name}
                      onKeyDown={this.onKeyDown}
                      onChange={(e) => {
                        this.setMyValue("Name", e);
                      }}
                      required
                    />
                  </AutoFocusInside>
                </DimsFromGroup>
                <DimsFromGroup label="Phone">
                  <DimsInput
                    name="Phone"
                    minLength="10"
                    value={this.state.Phone}
                    onChange={(e) => {
                      this.setMyValue("Phone", e);
                    }}
                    pattern="[0-9]{10}"
                    type="tel"
                    required
                  />
                </DimsFromGroup>
                <DimsFromGroup label="Email">
                  <DimsInput
                    name="Email"
                    value={this.state.Email}
                    onChange={(e) => {
                      this.setMyValue("Email", e);
                    }}
                    type="Email"
                    required
                  />
                </DimsFromGroup>
                <DimsFromGroup label="Password">
                  {this.props.user === null && (
                    <DimsInput
                      name="Password"
                      minlength="8"
                      min="10"
                      type="text"
                      required
                    />
                  )}
                  {this.props.user !== null && (
                    <DimsInput
                      name="Password"
                      minlength="8"
                      min="10"
                      type="text"
                    />
                  )}
                </DimsFromGroup>
                <DimsFromGroup label="Monthly">
                  <DimsInput
                    name="Monthly"
                    value={this.state.Monthly}
                    id="Monthly"
                    onChange={(e) => {
                      this.setMyValue("Monthly", e);
                    }}
                    type="number"
                    required
                  />
                </DimsFromGroup>
                <DimsFromGroup label="Hourly Rate">
                  <DimsInput
                    name="Hourly"
                    value={this.state.Hourly}
                    id="Hourly"
                    onChange={(e) => {
                      this.setMyValue("Hourly", e);
                    }}
                    type="number"
                    required
                  />
                </DimsFromGroup>
                <DimsFromGroup label="Address">
                  <DimsInput
                    name="Address"
                    id="Address"
                    value={this.state.Address}
                    onChange={(e) => {
                      this.setMyValue("Address", e);
                    }}
                    type="textarea"
                    required
                  />
                </DimsFromGroup>
              </TabPane>
              <TabPane tabId="2">
                <DimsFromGroup label="Select Designation">
                  <DimsSelect
                    name="Designation"
                    id="Designation"
                    defaultValue={this.props.designationSelect}
                    options={this.state.designationOptions}
                  />
                </DimsFromGroup>
                <DimsFromGroup label="User Type">
                  <DimsSelect
                    name="Type"
                    id="Type"
                    defaultValue={this.state.typeSelect}
                    options={this.state.typeOptions}
                  />
                </DimsFromGroup>
                <DimsFromGroup label="Reporting To">
                  <DimsSelect
                    name="Employee"
                    id="Employee"
                    defaultValue={this.props.reportingSelect}
                    options={this.state.userOptions}
                  />
                </DimsFromGroup>
                <DimsFromGroup label="Select Company">
                  <DimsSelect
                    tabIndex="3"
                    name="Company"
                    id="Company"
                    required
                    defaultValue={this.props.companySelect}
                    options={this.state.companyOptions}
                  />
                </DimsFromGroup>

                <DimsFromGroup label="Description">
                  <DimsInput
                    name="Description"
                    value={this.state.Description}
                    onChange={(e) => {
                      this.setMyValue("Description", e);
                    }}
                    id="Description"
                    type="textarea"
                  />
                </DimsFromGroup>
              </TabPane>
              <DimsButton type="submit"></DimsButton>
            </TabContent>
          </Form>
        </FocusLock>
      </DimsOffcanvas>
    );
  }
}
