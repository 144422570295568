import { Routes, Route, Navigate } from "react-router-dom";
import GeneralRoutes from "../pages/masters/general/Routes";
import TaskRoutes from "../pages/task/Routes";
import HRRoutes from  "../pages/hr/Routes"
import AppNav from "../layout/AppNav";
import Footer from "../layout/Footer";
import { Container } from "reactstrap";
import Login from "../pages/login/create/Login";
import NotFound from "../pages/NotFound";
import Dashboard from "../pages/Dashboard";
import Logout from "../pages/login/Logout"
import React from "react";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (sessionStorage.getItem("isLogin") === null ){
      sessionStorage.setItem("isLogin", 0);
    } 
    sessionStorage.setItem("employeeID", 0);
   
  }

  render() {
    return (
      <div className="App">
        {sessionStorage.getItem("isLogin") === "1" && (
          <div>
            <AppNav />

            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route exact path="/logout" element={<Logout />} />
              <Route path="general/*" element={<GeneralRoutes />} />
              <Route path="task/*" element={<TaskRoutes />} />
              <Route path="hr/*" element={<HRRoutes />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/" />}></Route>
            </Routes>

            <Footer />
          </div>
        )}
        {sessionStorage.getItem("isLogin") === "0" && (
          <div>
            <Container className="main h-100">
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/" />}></Route>
              </Routes>
            </Container>
            <Footer />
          </div>
        )}
      </div>
    );
  }
}
export default Main;
