import React, { Fragment } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  Nav,
  NavLink,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import Get from "../services/Get";
import Menu from "./Menu";

export default class AppNav extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      employee: JSON.parse(sessionStorage.getItem("user")),
      menu: [],
    };
    console.log(this.state.employee);
    this.load();
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  async load() {
    var menu = localStorage.getItem("Menu");

    if (menu === null) {
      var url = "/settings/Menu";
      Get(url, "").then((result) => {
        console.log(result);
        this.setState({ menu: result.data });
        localStorage.setItem("Menu", JSON.stringify(result.data));
      });
    } else {
      var json = JSON.parse(menu); 
      await this.setState({ menu: json });
      this.setState((prevState) => ({
        menu:json,
      }));
      console.log("state", this.state.menu);
    }
  }
  render() {
    return (
      <Fragment>
        <Navbar
          container
          className="navbar navbar-expand-lg shadow navbar-light bg-white"
          expand="md"
        > 
          <NavbarBrand href="/">{process.env.REACT_APP_TITLE}</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav left className=" ms-auto w-100" navbar>
              {this.state.menu.map((menu, i) => {
                return <Menu menu={menu} />;
              })}
            </Nav>
            <Nav right className="d-flex  logout" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {this.state.employee.employeeName}
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <NavLink className="p-0 m-0" href="/Logout">
                      Logout
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </Fragment>
    );
  }
}
