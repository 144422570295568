import * as axios from "axios";

function Get(url, data) {
  url = process.env.REACT_APP_API_URL + url;
  return axios({
    headers: {
      "dims-key": JSON.parse(sessionStorage.getItem("user")).uuid,
      "com-key": localStorage.getItem("key"),
      "Access-Control-Allow-Origin": "*",
    },
    url: url,
    data: data,
  });
}

//return default function
export default Get;
