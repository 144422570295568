import * as axios from "axios";
function getUUID() {
  if (
    sessionStorage.getItem("user") !== null &&
    sessionStorage.getItem("user") !== ""
  ) {
    return JSON.parse(sessionStorage.getItem("user")).uuid;
  }
  return "";
}

function Post(url, data) {
  url = process.env.REACT_APP_API_URL + url;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "dims-key": getUUID(),
      "com-key":  localStorage.getItem("key"),
      "Access-Control-Allow-Origin": "*",
    },
  };
  return axios.post(url, data, axiosConfig);
}

//return default function
export default Post;
