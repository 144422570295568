import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import DimsButton from "../../../components/DimsButton";
import Get from "../../../services/Get";
import DimsCheckBox from "../../../components/DimsCheckBox";
import DimsLink from "../../../components/DimsLink";
import Layout from "../Layout";
import DimsSelect from "../../../components/DimsSelect";
import DimsDatePicker from "../../../components/DimsDatePicker";
import DimsDataTable from "../../../components/DimsDataTable";
import helpers from "../../../helpers";
import DimsDateFormat from "../../../components/DimsDateFormat";
import TaskView from "../views/TaskView";
import Post from "../../../services/Post";

const ExpandedComponent = ({ data }) => <TaskView data={data}></TaskView>;
class TaskReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      off: false,
      companyOptions: [],
      projectOptions: [],
      userOptions: [],
      startDate: null,
      endDate: null,
      time: "",
      task: {},
      companySelect: "",
      taskDescription: "",
      taskName: "",
      employeeSelect: 0,
      employeeId: JSON.parse(sessionStorage.getItem("user")).employeeId,
    };
    this.startdatechange = this.startdatechange.bind(this);
    this.enddatechange = this.enddatechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.load();
  }
  handleSubmit() {
    var myTask = 0;
    if (document.getElementById("MyTask").checked) {
      myTask = 1;
    }
    var startDate = null;
    if (this.state.startDate != null) {
      startDate = helpers.systemdateFormat(this.state.startDate);
    }
    var endDate = null;
    if (this.state.endDate != null) {
      endDate = helpers.systemdateFormat(this.state.endDate);
    }
    var url = "/task/ProjectTask/report";
    var data = {
      employeeId: this.state.employeeSelect,
      projectId: 0,
      startDate: startDate,
      endDate: endDate,
      isMyTask: myTask,
    };
    Post(url, data)
      .then((result) => {
        console.log(result);
        this.setState({ taskData: result.data });
      })
      .catch((error) => {
        helpers.error(error);
      });
  }
  load() {
    var url = "/general/Company";
    // Get(url, "").then((result) => {
    //   console.log(result);
    //   var company = [];
    //   result.data.map((row, i) => {
    //     return company.push({ value: row.companyId, label: row.companyName });
    //   });
    //   this.setState({ companyOptions: company });
    // });

    url = "/general/Employee";
    Get(url, "").then((result) => {
      console.log(result);
      var user = [];
      result.data.map((row, i) => {
        return user.push({ value: row.employeeId, label: row.employeeName });
      });
      console.log(user);
      this.setState({ userOptions: user });
    });

    // url = "/task/Project/all";
    // Get(url, "").then((result) => {
    //   console.log(result);
    //   var project = [];
    //   result.data.map((row, i) => {
    //     return project.push({ value: row.projectId, label: row.projectName });
    //   });
    //   this.setState({ projectOptions: project });
    // });
  }
  startdatechange(e) {
    this.setState({ startDate: e });
  }
  enddatechange(e) {
    this.setState({ endDate: e });
  }
  render() {
    var columns = [
      {
        name: "Assigned Date",
        selector: (row) => (
          <div>{helpers.timestampToDate(row.uiTimeStamp)}</div>
        ),
      },
      {
        name: "Completion Date",
        selector: (row) => (
          <div>
            {" "}
            <DimsDateFormat date={row.completionDate}></DimsDateFormat>{" "}
          </div>
        ),
      },
      {
        name: "Name",
        selector: (row) => row.taskName,
      },
      {
        name: "Created By",
        selector: (row) => row.createdEmployee.employeeName,
      },

      {
        name: " Assigned to",
        selector: (row) => row.employee.employeeName,
      },
      // {
      //   name: "Project",
      //   selector: (row) => row.project.projectName,
      // },
      {
        name: "Status",
        selector: (row) => row.taskStatus.taskStatusName,
      },

      {
        name: "",
        selector: (row) => (
          <div>
            {(this.state.employeeId === row.createdEmployee.employeeId ||
              row.employeeId === this.state.employeeId) && (
              <DimsLink type="edit" to={"/task/update/" + row.uuid}></DimsLink>
            )}
          </div>
        ),
      },
    ];
    return (
      <Layout title="Task Report">
        <Row>
          <Col xs="4" className="mx-0 mb-2 p-0" md="1">
            Is My Task {"  "}
            <DimsCheckBox id="MyTask"></DimsCheckBox>
          </Col>
          <Col xs="8" className="mx-0 mb-2 p-0" md="3">
            <Row>
              <Col xs="5">Employee</Col>
              <Col xs="7">
                <DimsSelect
                  tabIndex="5"
                  name="Employee"
                  id="Employee"
                  onChange={(value) =>
                    this.setState({ employeeSelect: value.value })
                  }
                  options={this.state.userOptions}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="4" className="mb-2 " md="2">
            <DimsDatePicker
              placeholderText="Start Date"
              selected={this.state.startDate}
              onChange={this.startdatechange}
            ></DimsDatePicker>
          </Col>
          <Col xs="4" md="2">
            <DimsDatePicker
              placeholderText="End Date"
              selected={this.state.endDate}
              onChange={this.enddatechange}
            ></DimsDatePicker>
          </Col>
          <Col xs="4" md="2">
            <DimsButton type="search" onClick={this.handleSubmit} />
          </Col>
        </Row>

        <div className="z-0">
          <DimsDataTable
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            data={this.state.taskData}
            columns={columns}
          />
        </div>
      </Layout>
    );
  }
}

export default TaskReport;
