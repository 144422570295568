import React from "react";
import DimsOffcanvas from "../../../../components/DimsOffcanvas";
import DimsInput from "../../../../components/DimsInput";
import { Form, FormGroup, Label } from "reactstrap";
import DimsButton from "../../../../components/DimsButton";
import helpers from "../../../../helpers/";
import FocusLock, { AutoFocusInside } from "react-focus-lock";
import Post from "../../../../services/Post";
import Get from "../../../../services/Get";
import DimsFromGroup from "../../../../components/DimsFromGroup";
import DimsSelect from "../../../../components/DimsSelect";

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyOptions: [],
    };
    this.load();
  }
  load() {
    var url = "/general/Company/getgroupcompanies";
    Get(url, "").then((result) => {
      console.log(result);
      var company = [];
      result.data.map((row, i) => {
        return company.push({ value: row.companyId, label: row.companyName });
      });
      this.setState({ companyOptions: company });
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    var data = {
      companyName: e.target.Name.value,
      companyDescription: e.target.Description.value,
      companyPhone: e.target.Company.value,
      companyKey: e.target.Company.value,
      isGroupCompany: false,
      companyGroupID: e.target.Company.value,
    };
    var url = "/general/Company";
    Post(url, data)
      .then((result) => {
        if (result.status === 200) {
          helpers.success("Saved Successfully");
          document.getElementById("form").reset();
          document.getElementById("Name").focus();
        }
        console.log(result);
      })
      .catch(({ response }) => {
        // console.log(response.data);
        if (response !== undefined) {
          helpers.error(response.data.message);
        }
        // console.log(response.status);
        // console.log(response.headers);
      });
  }

  render() {
    return (
      <DimsOffcanvas
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title="Create Company"
      >
        <FocusLock>
          <Form id="form" onSubmit={this.handleSubmit}>
            <DimsFromGroup label="Name">
              <AutoFocusInside>
                <DimsInput name="Name" id="Name" required tabIndex="0" />
              </AutoFocusInside>
            </DimsFromGroup>
            <DimsFromGroup label="Phone">
              <DimsInput name="Phone" id="Phone" required tabIndex="0" />
            </DimsFromGroup>
            <DimsFromGroup label="Description">
              <DimsInput name="Description" id="Description" type="textarea" />
            </DimsFromGroup>
            <DimsFromGroup label="Select Company Group">
              <DimsSelect
                tabIndex="5"
                name="Company"
                id="Company"
                options={this.state.companyOptions}
              />
            </DimsFromGroup>

            <DimsButton color="primary" type="submit">
              Save
            </DimsButton>
          </Form>
        </FocusLock>
      </DimsOffcanvas>
    );
  }
}

export default Company;
