import React from "react";
import DimsButton from "../../../components/DimsButton";
import { Form } from "reactstrap";
import helpers from "../../../helpers";
import Post from "../../../services/Post";

class Login extends React.Component {
  login(e) {
    e.preventDefault();
    sessionStorage.setItem("isLogin", 0);
    sessionStorage.setItem("user", "");
    if (e.target.email.value === "" || e.target.password.value === "" ||e.target.key.value === ""  ) {
      helpers.error("Please fill all fields");
      return;
    }
    var data = {
      key: e.target.key.value,
      userName: e.target.email.value,
      passwod: e.target.password.value,
    };
    
    localStorage.setItem("key", e.target.key.value);
    var url = "/general/Employee/login";
    Post(url, data)
      .then((result) => {
        console.log();
        if (result.status === 200) {
          helpers.success("Welcome to Dashboard");
          sessionStorage.setItem("user", JSON.stringify(result.data));
          sessionStorage.setItem("isLogin", 1);
          sessionStorage.setItem("employeeID", result.data.employeeId);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(({ response }) => {
        if (response !== undefined) {
          helpers.error(response.data.message);
        }
      });
  }
  render() {
    return (
      <section className="vh-100">
        <div className="container-fluid h-custom mt-5">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="img-fluid"
                alt="Sample image"
              />
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <Form onSubmit={this.login}>
                <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                  <p className="lead fw-normal mb-0 me-3"> Login Ozone CRM</p>
                </div>
                <div className="form-outline my-3 mb-1">
                  <input
                    type="text"
                    name="key"
                    className="form-control"
                    placeholder="Company Key"
                    required
                  />
                </div>
                <div className="form-outline mb-1">
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Enter a valid email address"
                    required
                  />
                </div>

                <div className="form-outline mb-1">
                  <input
                    type="password"
                    name="password"
                    className="form-control "
                    placeholder="Enter password"
                    required
                  />
                </div>
                <div className="text-center text-lg-start mt-2 pt-2">
                  <DimsButton type="login" size="md">
                    Login
                  </DimsButton>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;
