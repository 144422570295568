import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Attandance from "../create/Attandance";
import DimsDatePicker from "../../../components/DimsDatePicker";
import Layout from "../Layout";
import DimsDateFormat from "../../../components/DimsDateFormat";
import Get from "../../../services/Get";
import helpers from "../../../helpers";
import DimsDataTable from "../../../components/DimsDataTable";
import DimsButton from "../../../components/DimsButton";
import Post from "../../../services/Post";
import swal from 'sweetalert';
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
 

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      data: [],
    };
    this.datechange = this.datechange.bind(this);
    this.load = this.load.bind(this);
    this.fromClient = this.fromClient.bind(this);
    this.load(new Date());
    this.remove = this.remove.bind(this);
  }
  remove(row, index) {
    swal("Do you want to delete this ?", {
      buttons: ["Cancel", true],
      dangerMode: true,
    })
      .then((value) => {
        if (value) {
          var url = "/hr/Attendance/DeleteOne/" + row.uuid;
          Post(url, "").then((result) => {
            console.log(result);
            helpers.success("Successfully Deleted")
            this.load(this.state.startDate);
          })
        }
      });
  }
  datechange(e) {
    this.setState({ startDate: e });
    this.load(e);
  }
  fromClient() {
    this.load(this.state.startDate);
  }
  load(date) {
    var url = "/hr/Attendance/" + helpers.systemdateFormat(date, "-");
    Get(url, "").then((result) => {
      console.log("Load ", result);
      this.setState({ data: result.data });
    });
    console.log(this.state.projectOptions);
  }
  render() {
    var columns = [
      {
        name: "Employee",
        selector: (row) => row.employee.employeeName,
      },
      {
        name: "Project",
        selector: (row) => row.project.projectName,
      },
      {
        name: "Advance",
        wrap:true,
        width:'80px',
        selector :(row)=>row.advanceAmount
      },
      {
        name: "W-Hours",
        width:'80px',
        selector: (row) => row.hours,
      },
      {
        name: "TA",
        width: '80px',
        selector: (row) => row.ta,
      },
      {
        name: "Wage",
        width: "80px",
        selector: (row) => row.wage,
      },
      {
        name: "",
        width: "80px",
        selector: (row, index) => (
          <DimsButton
            type="delete"
            onClick={(e) => {
              this.remove(row, index);
            }}
          />
        ),
      },
    ];

    return (
      <Layout title="Attandance">
        <Row>
          <Col sm="12" md="6">
            <Row>
              <Col sm="4">
                <DimsDatePicker
                  selected={this.state.startDate}
                  onChange={this.datechange}
                />
              </Col>

              <Col sm="12" className="z-0">

                <ExcelFile element={<button>Download Data</button>}>
                  <ExcelSheet data={this.state.data} name="Employees">
                    <ExcelColumn label="Name" value="employeeId" />
                    <ExcelColumn label="Wallet Money" value="employeeId" />
                    <ExcelColumn label="Gender" value="employeeId" /> 
                  </ExcelSheet>
                </ExcelFile>
                <DimsDataTable data={this.state.data} columns={columns} />
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="6">
            <Attandance
              click={this.fromClient}
              startDate={this.state.startDate}
            />
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default Attendance;
