import React from "react";
import { Col,Row, Container  } from "reactstrap";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer fixed-bottom mt-auto py-3 bg-light">
        <Container>
          <Row>
            <Col> <span className="text-muted">Powerd by Dimind Innovations</span> </Col>
            <Col className="text-end"> <span className="text-muted">Version :{ process.env.REACT_APP_VERSION }</span> </Col>
          </Row> 
        </Container>
      </footer>
    );
  }
}
