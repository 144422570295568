import swal from "sweetalert";
import { toast } from "react-toastify";
import * as moment from "moment";

const helpers = {
  dateFormat(date) {
    return moment(date).format("DD-MM-YYYY");
  },
  systemdateFormat(date, fm = "/") {
    if (fm === "/") return moment(date).format("YYYY/MM/DD");
    else return moment(date).format("YYYY-MM-DD");
  },
  timestampToDate(value) {
    return moment(value).format("DD-MM-YYYY hh:mm:ss a");
  },
  timestamp() {
    return moment().format("x");
  },
  success: function(msg) {
    //swal(msg);
    toast(msg, {
      type: "success",
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  },
  error: function(msg) {
    toast(msg, {
      type: "error",
      autoClose: 5000,
      position: toast.POSITION.TOP_CENTER,
    });
  },
  Info: function(msg) {
    toast(msg, {
      type: "info",
      autoClose: 1000,
      position: toast.POSITION.TOP_CENTER,
    });
  },
};

export default helpers;
