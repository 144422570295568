import React from "react";
import DimsOffcanvas from "../../../components/DimsOffcanvas";
import DimsInput from "../../../components/DimsInput";
import DimsButton from "../../../components/DimsButton";
import DimsFromGroup from "../../../components/DimsFromGroup";
import helpers from "../../../helpers";
import FocusLock, { AutoFocusInside } from "react-focus-lock";
import { Form } from "reactstrap";
import Get from "../../../services/Get";
import DimsSelect from "../../../components/DimsSelect";
import DimsDatePicker from "../../../components/DimsDatePicker";
import Post from "../../../services/Post";

class Task extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      off: false,
      companyOptions: [],
      projectOptions: [],
      userOptions: [],
      startDate: new Date(),
      time: "",
      task: {},
      companySelect: "",
      taskDescription: "",
      taskName: "",
      employeeSelect: [],
      employeeId : JSON.parse(sessionStorage.getItem("user")).employeeId
    };
    this.datechange = this.datechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onOpend = this.onOpend.bind(this);
    this.taskDescription = this.taskDescription.bind(this);
    this.taskName = this.taskName.bind(this);
    this.load();
  }
  onOpend() {
    if (this.props.task !== null)
      this.setState({
        taskDescription: this.props.task.taskDescription,
        taskName: this.props.task.taskName,
        startDate: new Date(
          helpers.systemdateFormat(this.props.task.completionDate)
        ),
      });
    else {
      this.setState({
        taskDescription: "",
        taskName: "",
        startDate: new Date(),
      });
    }
  }
  load() {
    var url = "/general/Company";
    Get(url, "").then((result) => {
      console.log(result);
      var company = [];
      result.data.map((row, i) => {
        return company.push({ value: row.companyId, label: row.companyName });
      });
      this.setState({ companyOptions: company });
    });

    url = "/general/Employee";
    Get(url, "").then((result) => {
      console.log(result);
      var user = [];
      result.data.map((row, i) => {
        return user.push({ value: row.employeeId, label: row.employeeName });
      });
      console.log(user);
      this.setState({ userOptions: user });
    });

    url = "/task/Project/all";
    Get(url, "").then((result) => {
      console.log(result);
      var project = [];
      result.data.map((row, i) => {
        return project.push({ value: row.projectId, label: row.projectName });
      });
      this.setState({ projectOptions: project });
    });
  }
  taskDescription(e) {
    this.setState({ taskDescription: e.target.value });
  }
  taskName(e) {
    this.setState({ taskName: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    var projectTaskID = 0;
    if (this.props.task !== null) {
      projectTaskID = this.props.task.projectTaskID;
    }
    var data = {
      projectTaskID: projectTaskID,
      taskName: e.target.Name.value,
      taskDescription: e.target.Description.value,
      completionDate: this.state.startDate,
      projectId: e.target.Project.value,
      employeeId: e.target.Employee.value,
      taskStatusId: 1,
      createdEmployeeId :this.state.employeeId,
      uiTimeStamp: helpers.timestamp(),
    };
    var url = "/task/ProjectTask";
    Post(url, data)
      .then((result) => {
        if (result.status === 200) {
          helpers.success("Saved Successfully");
          setTimeout(() => {
            this.props.toggle();
          }, 1000);
        }
      })
      .catch(({ response }) => {
        helpers.error(response.data.message);
      });
  }
  datechange(e) {
    this.setState({ startDate: e });
  }
  

  render() {
    return (
      <DimsOffcanvas
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title="Create Task"
        onOpened={this.onOpend}
      >
        <FocusLock>
         
          <Form id="form" onSubmit={this.handleSubmit}>
            <DimsFromGroup label="Name">
              <AutoFocusInside>
                <DimsInput
                  tabIndex="1"
                  name="Name"
                  id="Name"
                  onChange={this.taskName}
                  value={this.state.taskName}
                  onKeyDown={this.onKeyDown}
                  required
                />
              </AutoFocusInside>
            </DimsFromGroup>
            <DimsFromGroup label="Description">
              <DimsInput
                name="Description"
                onChange={this.taskDescription}
                value={this.state.taskDescription}
                tabIndex="2"
                type="textarea"
              />
            </DimsFromGroup>
            {/* <DimsFromGroup label="Select Company">
              <DimsSelect
                tabIndex="2"
                name="company"
                defaultValue={this.state.companySelect}
                options={this.state.companyOptions}
              />
            </DimsFromGroup> */}

            <DimsFromGroup label="Completion Date">
              <DimsDatePicker
                tabIndex="3"
                selected={this.state.startDate}
                onChange={this.datechange}
              />
            </DimsFromGroup>

            <DimsFromGroup label="Select Employee">
              <DimsSelect
                tabIndex="5"
                name="Employee"
                id="Employee"
                defaultValue={this.props.employeeSelect}
                options={this.state.userOptions}
              />
            </DimsFromGroup>
            <DimsFromGroup label="Select Project">
              <DimsSelect
                tabIndex="6"
                name="Project"
                defaultValue={this.props.projectSelect}
                options={this.state.projectOptions}
              />
            </DimsFromGroup>

            <DimsButton tabIndex="7" type="submit"></DimsButton>
          </Form>
        </FocusLock>
      </DimsOffcanvas>
    );
  }
}

export default Task;
