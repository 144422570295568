import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import DimsButton from "../../../components/DimsButton";
import DimsDatePicker from "../../../components/DimsDatePicker";
import Get from "../../../services/Get";
import DimsSelect from "../../../components/DimsSelect";
import DimsDataTable from "../../../components/DimsDataTable";
import Layout from "../Layout";
import Post from "../../../services/Post";
import helpers from "../../../helpers";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class AttendanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attantanceData: [],
      startDate: new Date(),
      endDate: new Date(),
      projectOptions: [],
      userOptions: [],
      employeeSelect: null,
      projectSelect: null,
    };
    this.load();
    this.employeeSelect = this.employeeSelect.bind(this);
    this.projectSelect = this.projectSelect.bind(this);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.search = this.search.bind(this);
  }
  startDateChange(e) {
    this.setState({ startDate: e });
    this.load(e);
  }
  endDateChange(e) {
    this.setState({ endDate: e });
    this.load(e);
  }
  load() {
    var url = "/task/Project/all";
    Get(url, "").then((result) => {
      var project = [];
      result.data.map((row, i) => {
        return project.push({ value: row.projectId, label: row.projectName });
      });
      this.setState({ projectOptions: project });
    });
    url = "/general/Employee";
    Get(url, "").then((result) => {
      var user = [];
      result.data.map((row, i) => {
        return user.push({ value: row.employeeId, label: row.employeeName });
      });
      this.setState({ userOptions: user });
    });
  }
  employeeSelect(e) {
    this.setState({ employeeSelect: e });
  }
  projectSelect(e) {
    this.setState({ projectSelect: e });
  }
  toggleOff() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
  }
  search() {
    var url = "/hr/Attendance/report";
    var projectId = 0;
    if (this.state.employeeSelect === null) {
      helpers.error("Select Employee");
      return;
    }
    if (this.state.projectSelect !== null) {
      projectId = this.state.projectSelect.value;
    }
    //this.state.employeeSelect.value
    var data = {
      projectId: projectId,
      employeeId: this.state.employeeSelect.value,
      startDate: helpers.systemdateFormat(this.state.startDate, "-"),
      endDate: helpers.systemdateFormat(this.state.endDate, "-"),
    };
    Post(url, data).then((result) => {
      console.log("Load ", result);
      this.setState({ data: result.data });
    });
  }
  render() {
    var columns = [
      {
        name: "Date",
        selector: (row) => helpers.dateFormat(row.attendanceDate),
      },
      {
        name: "Employee",
        selector: (row) => row.employee.employeeName,
      },
      {
        name: "Project",
        selector: (row) => row.project.projectName,
      },
      {
        name: "Working Hours",
        selector: (row) => row.hours,
      },
      {
        name: "TA",
        selector: (row) => row.ta,
      },
      {
        name: "Advance Amount",
        selector: (row) => row.advanceAmount,
      },

      {
        name: "Wage",
        selector: (row) => row.wage,
      },
      {
        // name: "",
        // selector: (row, index) => (
        //   <DimsButton
        //     type="delete"
        //     onClick={(e) => {
        //       this.remove(index);
        //     }}
        //   />
        // ),
      },
    ];
    return (
      <Layout title="Attandance">
        <Row>
          <Col md="2" sm="12">
            Select employee
            <DimsSelect
              name="Employee"
              id="Employee"
              options={this.state.userOptions}
              onChange={this.employeeSelect}
            />
          </Col>
          <Col md="2" sm="12">
            Select Project
            <DimsSelect
              name="Project"
              id="Project"
              options={this.state.projectOptions}
              onChange={this.projectSelect}
            />
          </Col>
          <Col md="2" sm="12">
            Start Date
            <DimsDatePicker
              selected={this.state.startDate}
              onChange={this.startDateChange}
            />
          </Col>
          <Col md="2" sm="12">
            End Date
            <DimsDatePicker
              selected={this.state.endDate}
              onChange={this.endDateChange}
            />
          </Col>
          <Col md="2" sm="12">
            <br />
            <DimsButton type="search" onClick={this.search} />
          </Col>
        </Row>
        <Row>

          <Col sm="12" className="z-0">
            <ExcelFile element={<button>Download Data</button>}>
              <ExcelSheet data={this.state.data} name="Employees">
                <ExcelColumn label="Name" value="employeeName" />
                <ExcelColumn label="Date" value={(col) => helpers.dateFormat(col.attendanceDate)} />
                <ExcelColumn label="Project" value={(col) => col.project.projectName} />
                <ExcelColumn label="Hours" value="hours" />
                <ExcelColumn label="TA" value="ta" />
                <ExcelColumn label="Advance Amount" value="advanceAmount" />
                <ExcelColumn label="Wage" value="wage" />
              </ExcelSheet>
            </ExcelFile>
            <DimsDataTable data={this.state.data} columns={columns} />
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default AttendanceReport;
