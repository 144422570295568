import React from "react";

class NotFound extends React.Component { 
  render() {
    // Use the `ref` callback to store a reference to the text input DOM
    // element in an instance field (for example, this.textInput).
    return (
      <div>
        log 
      </div>
    );
  }
}

export default NotFound;
