import React from "react";
import Layout from "../Layout";
import TaskStatus from "../create/TaskStatus";
import Get from "../../../../services/Get";
import DimsDataTable from "../../../../components/DimsDataTable";
import helpers from "../../../../helpers/";
import DimsButton from "../../../../components/DimsButton";

class TaskStatusList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      off: false,
      projectTaskStatusData: [],
    };
    this.toggleOffCanvas = this.toggleOffCanvas.bind(this);
    this.editClick = this.editClick.bind(this);
    this.load();
  }
  load() {
    var url = "/task/ProjectTaskStatus";
    Get(url, "")
      .then((result) => {
        console.log(result);
        this.setState({ projectTaskStatusData: result.data });
      })
      .catch((error) => {
        helpers.error(error);
      });
  }
 
  toggleOffCanvas() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
    this.load();
  }
  editClick(row) { 
    this.setState((prevState) => ({
      off: !prevState.off,
      uuid: row.guid,
    }));
  }
  render() {
    var columns = [
      {
        name: "Name",
        selector: (row) => row.taskStatusName,
      },
      {
        name: "Description",
        selector: (row) => row.taskStatusDescription,
      },
      {
        name: "",
        selector: (row) => (
          <div>
            <DimsButton
              type="edit"
              onClick={(e) => this.editClick(row)}
            ></DimsButton>{" "}
          </div>
        ),
      },
    ];
    return (
      <Layout title="Task Status" isButton={true} url={this.toggleOffCanvas}>
        <TaskStatus
          isOpen={this.state.off}
          uuid={this.state.uuid}
          toggle={this.toggleOffCanvas}
        ></TaskStatus>
        <DimsDataTable data={this.state.projectTaskStatusData} columns={columns} />
      </Layout>
    );
  }
}  

export default TaskStatusList;