import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import DimsButton from "../components/DimsButton";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
export default class PageTittle extends React.Component {
  render() {
    return (
      <Fragment>
        {this.props.url === "" ||
          (this.props.url === undefined && (
            <Row>
              {" "}
              <Col xs="6">
                <h4 className="col-md-12"> {this.props.name} </h4>
              </Col>
            </Row>
          ))}
         {this.props.url !==  undefined   && ( <Row>
          <Col xs="6">
            <h4 className="col-md-5"> {this.props.name} </h4>
          </Col>
          <Col xs="6">
            {this.props.isButton === true && (
              <DimsButton
                type="create"
                onClick={this.props.url}
                className="float-end "
              />
            )}
            {this.props.isButton === false && this.props.linkType !== "back" && (
              <Link
                color="primary"
                className="btn btn-info  float-end "
                to={this.props.url}
              >
                <FiPlus />
              </Link>
            )}
            {this.props.isButton === false && this.props.linkType === "back" && (
              <Link
                color="primary"
                className="btn btn-info  float-end "
                to={this.props.url}
              >
                <FiArrowLeft />
              </Link>
            )}
          </Col>
        </Row>)}
      </Fragment>
    );
  }
}
