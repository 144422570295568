import React from "react";  
import { Routes, Route,Navigate } from "react-router-dom";
import Companies from "./list/Company";
import Branch from "./list/Branch"; 
import Projectes from "./list/Project";
import Users from "./list/User";
import Designations from "./list/Designation";
import TaskStatus from "./list/TaskStatus"
export default class GeneralRoutes extends React.Component {
  render() {
    return ( 
        <Routes>
          <Route path="companies" element={<Companies />} />
          <Route path="braches" element={<Branch />} /> 
          <Route path="projects" element={<Projectes />} /> 
          <Route path="users" element={<Users />} /> 
          <Route path="designation" element={<Designations />} /> 
          <Route path="taskstatus" element={<TaskStatus />} /> 
          <Route path="*" element={<Navigate to="/404" />}></Route>
        </Routes>  
    );
  }
}
