import React from "react";
import * as moment from "moment";

export default class DimsDateFormat extends React.Component {
  render() { 
    return (
      <React.Fragment>
        {moment( this.props.date).format("DD-MM-YYYY")}
      </React.Fragment>
    );
  }
}
