import React from "react";
import PageTittle from "../layout/PageTittle";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
export default class DimsCard extends React.Component {
  render() {
    return (
      <Card className="border-primary m-3 shadow">
        <CardHeader>
          <PageTittle name={this.props.title} isButton={this.props.isButton} linkType = { this.props.linkType}  url={this.props.url} />
        </CardHeader>
        <CardBody>{this.props.children}</CardBody>
        <CardFooter></CardFooter>
      </Card>
    );
  }
}
