import React from "react";
import { Input } from "reactstrap";

class DimsInput extends React.Component {
  render() {
    return (
      <Input bsSize="sm" {...this.props}>
        {this.props.children}
      </Input>
    );
  }
}

export default DimsInput;
