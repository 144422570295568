import React, { Component} from "react";
import DimsOffcanvas from "../../../../components/DimsOffcanvas";
import DimsInput from "../../../../components/DimsInput"
import {  Form, FormGroup, Label } from "reactstrap";
import helpers from "../../../../helpers";
import DimsButton from "../../../../components/DimsButton";
import Post from "../../../../services/Post";

class Designation extends Component {
   
  handleSubmit(e) {
    e.preventDefault();
    var data = {
      name: e.target.Name.value,
      description: e.target.Description.value,
     
    };
    var url = "/general/Designation";
    Post(url, data)
      .then((result) => {
        if (result.status === 200) {
          helpers.success("Saved Successfully");
          document.getElementById("form").reset();
          document.getElementById("Name").focus();
        }
        console.log(result);
      })
      .catch(({ response }) => {
        // console.log(response.data);
        // helpers.error(response.data.message);
        // console.log(response.status);
        // console.log(response.headers);
      });
    helpers.error(e.target.name.value);
  }
  render() {
    return (
      <DimsOffcanvas
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title="Create Project"
      >
        <Form  id="form" onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label> Name </Label>
            <DimsInput name="Name" required />
          </FormGroup>
          <FormGroup>
            <Label> Description </Label>
            <DimsInput name="Description" type="textarea" />
          </FormGroup>
          <DimsButton    type="submit">
              Save
            </DimsButton>
        </Form>
      </DimsOffcanvas>
    );
  }
}

export default Designation;
