import React from "react";
import Layout from "../Layout";
import Get from "../../../services/Get";
import DimsDataTable from "../../../components/DimsDataTable";
import helpers from "../../../helpers";
import DimsLink from "../../../components/DimsLink";
import TaskView from "../views/TaskView";

const ExpandedComponent = ({ data }) => <TaskView data={data}></TaskView>;

class AssignedTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      off: false,
      taskData: [],
    };
    this.toggleOff = this.toggleOff.bind(this);
    this.load();
  }
  load() {
    var url = "/task/ProjectTask";
    Get(url, "")
      .then((result) => {
        console.log(result);
        this.setState({ taskData: result.data });
      })
      .catch((error) => {
        helpers.error(error);
      });
  }
  toggleOff() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
    this.load();
  }
  render() {
    var columns = [
      {
        name: "Assigned Date",
        selector: (row) => (
          <div>{helpers.timestampToDate(row.uiTimeStamp)}</div>
        ),
      },
      {
        name: "Completion Date",
        selector: (row) => <div> {helpers.dateFormat(row.completionDate)}</div>,
      },
      {
        name: "Name",
        selector: (row) => row.taskName,
      },
      {
        name: "Created By",
        selector: (row) => row.createdEmployee.employeeName,
      },

      {
        name: " Assigned to",
        selector: (row) => row.employee.employeeName,
      },
      // {
      //   name: "Project",
      //   selector: (row) => row.project.projectName,
      // },
      {
        name: "Status",
        selector: (row) => row.taskStatus.taskStatusName,
      },
      {
        name: "",
        selector: (row) => (
          <div>
            <DimsLink type="edit" to={"/task/update/" + row.uuid}></DimsLink>
          </div>
        ),
      },
    ];
    return (
      <Layout title="Assigned Tasks">
        <DimsDataTable
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          data={this.state.taskData}
          columns={columns}
        />
      </Layout>
    );
  }
}

export default AssignedTasks;
