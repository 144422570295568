import React from "react"; 
import { Link } from "react-router-dom";
import { MdOutlineAddTask } from "react-icons/md";


export default class DimsLink extends React.Component {
  render() {
    
    return (
        <React.Fragment>
        { this.props.type ==='edit' && <Link 
        className="btn m-1   btn-sm btn-warning  float-end "
        {...this.props}
      >
        <MdOutlineAddTask /> 
      </Link> }
      </React.Fragment>
    );
  }
}
