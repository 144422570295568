import React from "react";
import DimsDateTimeFormat from "../../../components/DimsDateTimeFormat";

export default class TaskItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeId: JSON.parse(sessionStorage.getItem("user")).employeeId,
    };
    console.log(this.state.employeeId);
  }
  render() {
    console.log(this.props.row);
    return (
      <div>
        {this.state.employeeId.toString() !==
          this.props.row.employeeId.toString() && (
          <div
            className="text-end p-3 m-2"
            style={{ border: "2px solid #ddd", borderRadius: "5px" }}
          >
            <h6>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.row.taskAssignedDescription,
                }}
              />{" "}
            </h6>
            <p>
              {" "}
              TaskStatus : {this.props.row.taskStatus.taskStatusName} <br />
              Commented By : {this.props.row.employee.employeeName} <br />
              Created Date
              <DimsDateTimeFormat
                date={this.props.row.uiDate}
              ></DimsDateTimeFormat>
            </p>
          </div>
        )}
        {this.state.employeeId.toString() ===
          this.props.row.employeeId.toString() && (
          <div
            className="text-start p-3 m-2"
            style={{ background: "#eee", borderRadius: "5px" }}
          >
            <h6>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.row.taskAssignedDescription,
                }}
              />{" "}
            </h6>
            <p>
              {" "}
              TaskStatus : {this.props.row.taskStatus.taskStatusName} <br />
              Created Date
              <DimsDateTimeFormat
                date={this.props.row.uiDate}
              ></DimsDateTimeFormat>
            </p>
          </div>
        )}
      </div>
    );
  }
}
