import React  from "react";
import Layout from "../Layout";
import Company from "../create/Company";
import Get from "../../../../services/Get";
import DimsDataTable from "../../../../components/DimsDataTable"; 

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      off: false,
      companyData: [],
    };     
    this.toggleOff = this.toggleOff.bind(this);
    this.load();
  }
  load() {
    var url = "/general/Company";
    Get(url, "").then((result) => {
      console.log(result);
      this.setState({ companyData: result.data });
    });
  }
   
   
  toggleOff() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
    this.load();
  }
  render() {
    var columns = [
      {
        name: "Name",
        selector: (row) => row.companyName,
      },
      {
        name: "Description",
        selector: (row) => row.companyDescription,
      },
      
    ];

    return (
      
      <Layout title="Companies" isButton={true} url={this.toggleOff}>  
        <Company isOpen={this.state.off} toggle={this.toggleOff}></Company>
        <DimsDataTable data={this.state.companyData} columns={columns} />
      </Layout>
    );
  }
}

export default Companies;
