import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Attendance from "./list/Attendance";
import AttendanceReport from "./list/AttendanceReport";
import AttendanceSummeryReport from "./list/AttendanceSummeryReport";

export default class HRRoutes extends React.Component {

  render() {
    return (
      <Routes>
        <Route path="attendance/project" element={<AttendanceReport />}></Route>
        <Route path="attendance" element={<Attendance />}></Route>
        <Route path="attendancesummery" element={<AttendanceSummeryReport />}></Route>

        <Route path="*" element={<Navigate to="/404" />}></Route>
      </Routes>
    );
  }
}
