import React from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";

export default class DimsOffcanvas extends React.Component {
  render() {
    return (
      <Offcanvas
        direction="end"
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        {...this.props}
      >
        <OffcanvasHeader toggle={this.props.toggle}>
          {this.props.title}
        </OffcanvasHeader>
        <OffcanvasBody>{this.props.children}</OffcanvasBody>
      </Offcanvas>
    );
  }
}
