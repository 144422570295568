import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import DimsButton from "../../../components/DimsButton";
import DimsDatePicker from "../../../components/DimsDatePicker";
import DimsInput from "../../../components/DimsInput";
import DimsSelect from "../../../components/DimsSelect";
import Post from "../../../services/Post";
import helpers from "../../../helpers";
import Get from "../../../services/Get";
import { data } from "jquery";
import DimsDataTable from "../../../components/DimsDataTable";

class Attandance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attantanceData: [],
      projectOptions: [],
      userOptions: [],
      employeeSelect: null,
      projectSelect: null,
      wh: 0,
      ta: 0,
      advanceAmount: 0,
      save: [],
    };
    this.load = this.load.bind(this);
    this.load();
    this.datechange = this.datechange.bind(this);
    this.add = this.add.bind(this);
    this.employeeSelect = this.employeeSelect.bind(this);
    this.projectSelect = this.projectSelect.bind(this);
    this.remove = this.remove.bind(this);
    this.saveAll = this.saveAll.bind(this);
  }
  datechange(e) {
    this.setState({ startDate: e });
  }

  load() {
    var url = "/task/Project/all";
    Get(url, "").then((result) => {
      var project = [];
      result.data.map((row, i) => {
        return project.push({ value: row.projectId, label: row.projectName });
      });
      this.setState({ projectOptions: project });
    });
    url = "/general/Employee";
    Get(url, "").then((result) => {
      var user = [];
      result.data.map((row, i) => {
        return user.push({ value: row.employeeId, label: row.employeeName });
      });
      this.setState({ userOptions: user });
    });
  }

  toggleOff() {
    this.setState((prevState) => ({
      off: !prevState.off,
    }));
  }
  employeeSelect(e) {
    this.setState({ employeeSelect: e });
  }
  projectSelect(e) {
    this.setState({ projectSelect: e });
  }
  add() {
    if (this.state.employeeSelect === null) {
      helpers.error("Please select employee");
      return;
    }
    if (this.state.projectSelect === null) {
      helpers.error("Please select Project");
      return;
    }
    if (this.state.wh === 0) {
      helpers.error("Please add Working Hours");
      return;
    }
    if (this.state.wh < 0 || this.state.wh > 24) {
      helpers.error("Please add valid Working Hours");
      return;
    }
    if (this.state.ta === 0) {
      helpers.error("Please add TA");
      return;
    }
    var data = this.state.save;
    var empExist = 0;
    data.map((x, i) => {
      if (
        x.employeeId === this.state.employeeSelect.value &&
        x.projectId === this.state.projectSelect.value
      ) {
        empExist = 1;
      }
    });
    if (empExist === 1) {
      helpers.error("Already added this employee");
      return;
    }
    data.push({
      attendanceId: 0,
      employeeId: this.state.employeeSelect.value,
      employeeSelect: this.state.employeeSelect,
      projectSelect: this.state.projectSelect,
      attendanceDate: helpers.systemdateFormat(this.props.startDate),
      hours: this.state.wh,
      ta: this.state.ta,
      advanceAmount: this.state.advanceAmount,
      projectId: this.state.projectSelect.value,
    });
    this.setState({ save: data });
  }

  remove(index) {
    var data = this.state.save;
    data.splice(index, 1);
    this.setState({ save: data });
  }

  saveAll() {
    var url = "/hr/Attendance";
    Post(url, this.state.save)
      .then((result) => {
        if (result.status === 200) {
          helpers.success("Saved Successfully");
        }
        this.setState({ save: [] });
        this.props.click();
      })
      .catch(({ response }) => {
        // console.log(response.data);
        helpers.error(response.data.message);
        // console.log(response.status);
        // console.log(response.headers);
      });
  }
  render() {
    var columns = [
      {
        name: "Employee",
        selector: (row) => row.employeeSelect.label,
      },
      {
        name: "Project",
        selector: (row) => row.projectSelect.label,
      },
      {
        name: "Working Hours",
        selector: (row) => row.hours,
      },
      {
        name: "Advance Amount",
        selector :(row)=>row.advanceAmount
      },
      {
        name: "TA",
        with: "150px",
        selector: (row) => row.ta,
      },
      {
        name: "",
        selector: (row, index) => (
          <DimsButton
            type="delete"
            onClick={(e) => {
              this.remove(index);
            }}
          />
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col md="4" sm="12">
            Select employee
            <DimsSelect
              name="Employee"
              id="Employee"
              options={this.state.userOptions}
              onChange={this.employeeSelect}
            />
          </Col>

          <Col md="4" sm="12">
            Select Project
            <DimsSelect
              name="Project"
              id="Project"
              options={this.state.projectOptions}
              onChange={this.projectSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3" sm="12">
            Working Hours
            <DimsInput
              type="number"
              onChange={(e) => {
                this.setState({ wh: e.target.value });
              }}
            />
          </Col>
          <Col md="3" sm="12">
            TA
            <DimsInput
              type="number"
              onChange={(e) => {
                this.setState({ ta: e.target.value });
              }}
            />
          </Col>
          <Col md="3" sm="12">
            Advance Amount
            <DimsInput
              type="number"
              onChange={(e) => {
                this.setState({ advanceAmount: e.target.value });
              }}
            />
          </Col>

          <Col md="3" sm="12">
            <br />
            <DimsButton type="create" onClick={this.add} />
          </Col>
        </Row>
        <Row>
          <Col className="z-0">
            {this.state.save.length !== 0 && (
              <DimsDataTable
                data={JSON.parse(JSON.stringify(this.state.save))}
                columns={columns}
              />
            )}
            <br />
          </Col>
        </Row>
        {this.state.save.length !== 0 && (
          <Row>
            <Col sm="8"></Col>
            <Col sm="4">
              <DimsButton type="submit" onClick={this.saveAll} />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default Attandance;
